// export const baseURL = window.VUE_APP_BASE_URL;

export const AUTH_LOGIN = "/api/auth/login-safe";
export const AUTH_USER = "/api/auth/self";
export const AUTH_PRIVILEGES = "/api/auth/privileges";

export const USER_LIST = "/api/user/list";
export const USER_LIST_DELETED = "/api/user/list-deleted";
export const USER_GET = "/api/user/get";
export const USER_CREATE = "/api/user/create";
export const USER_UPDATE = "/api/user/update";
export const USER_REMOVE = "/api/user/delete";
export const USER_RESTORE = "/api/user/restore";
export const USER_TOTAL = "/api/user/count-total";
export const USER_TOTAL_BY_ROLES = "/api/user/count-by-roles";

export const USER_RESOURCE_GET = "/api/user/resource/get";
export const USER_RESOURCE_LIST = "/api/user/resource/list";
export const USER_RESOURCE_UPDATE = "/api/user/resource/update";
export const USER_RESOURCE_DELETE = "/api/user/resource/delete";
export const USER_RESOURCE_CREATE = "/api/user/resource/create";
export const USER_RESOURCE_RENAME = "/api/user/resource/rename";

export const REPO_ITEM_LIST = "/api/repository/:item:/list";
export const REPO_ITEM_DELETE = "/api/repository/:item:/delete/:typeId:";
export const REPO_ITEM_CREATE = "/api/repository/:item:/create";
export const REPO_ITEM_UPDATE = "/api/repository/:item:/update";
export const TERMS_ITEM_CREATE = "/api/repository/terms/create";
export const TERMS_ITEM_UPDATE = "/api/repository/terms/update/:channel:";

export const ACTUATOR_ROUTES = "/actuator/routes";
export const ACTUATOR_ROUTES_REFRESH = "/actuator/refresh";
export const ACTUATOR_ROUTES_HEALTH = "/actuator/health";
export const ACTUATOR_ROUTES_INFO = "/actuator/info";
export const ACTUATOR_ROUTES_LOGFILE = "/actuator/logfile";
export const ACTUATOR_ROUTES_HTTP_TRACE = "/actuator/httptrace";

export const CONFIG_GET_ALL = "/api/config/all";

export const ONBOARDING_MFS_COUNT_REGISTRATIONS = "/api/onebank/activity/count-registrations";
export const ONBOARDING_MFS_GET_BY_UID = "/api/onebank/activity/registration";
export const ONBOARDING_MFS_REGISTRATIONS = "/api/onebank/activity/filter-registrations";
export const ONBOARDING_MFS_GENERATE_REPORT = "/api/onebank/activity/generate-report";
export const ONBOARDING_USER_IDS = "/api/onebank/activity/registration/users";

export const ONBOARDING_CBS_COUNT_REGISTRATIONS = "/api/onebank/activity/count-cbs-registrations";
export const ONBOARDING_CBS_GET_BY_UID = "/api/onebank/activity/registration/cbs";
export const ONBOARDING_CBS_REGISTRATIONS = "/api/onebank/activity/filter-cbs-registrations";
export const ONBOARDING_CBS_GENERATE_REPORT = "/api/onebank/activity/generate-cbs-report";
export const ONBOARDING_CBS_SELF_REVIEW = "/api/onebank/cbs-self/review";

export const FACE_LIVENESS_REQUESTS = "/api/face/requests/liveness-requests";
export const FACE_LIVENESS_REQUESTS_BY_USER = "/api/face/requests/liveness-requests-by-user";
export const FACE_LIVENESS_REQUEST_SINGLE = "/api/face/requests/liveness-request";
export const FACE_LIVENESS_REQUESTS_OVER_TIME = "/api/face/requests/liveness-requests-over-time";
export const LIVENESS_CHECK = "/api/face/liveness";

export const FACE_MATCH_REQUESTS = "/api/face/requests/match-requests";
export const FACE_MATCH_REQUESTS_BY_USER = "/api/face/requests/match-requests-by-user";
export const FACE_MATCH_REQUEST_SINGLE = "/api/face/requests/match-request";
export const FACE_MATCH_REQUESTS_OVER_TIME = "/api/face/requests/match-requests-over-time";
export const FACE_MATCH_CHECK = "/api/face/match";

export const FACE_REQUEST_IMAGES = "/api/face/requests/request-images";
export const FACE_REQUEST_IMAGES_BY_USER = "/api/face/requests/request-images-by-user";
export const FACE_REQUEST_IMAGE_SINGLE = "/api/face/requests/request-image";

export const OCR_REQUESTS = "/api/ocr/requests/ocr-requests";
export const OCR_REQUESTS_BY_USER = "/api/ocr/requests/ocr-requests-by-user";
export const OCR_REQUEST_SINGLE = "/api/ocr/requests/ocr-request";
export const OCR_REQUESTS_OVER_TIME = "/api/ocr/requests/ocr-requests-over-time";
export const OCR_NID = "/api/ocr/nid";
export const OCR_NID_TEXT = "/api/ocr/parse-nid";

export const PARSE_REQUESTS = "/api/ocr/requests/parse-requests";
export const PARSE_REQUESTS_BY_USER = "/api/ocr/requests/parse-requests-by-user";
export const PARSE_REQUEST_SINGLE = "/api/ocr/requests/parse-request";
export const PARSE_REQUEST_TEXT = "/api/ocr/requests/parse-request-text";
export const PARSE_REQUESTS_OVER_TIME = "/api/ocr/requests/parse-requests-over-time";

export const NID_OCR_CARDS = "/api/ocr/requests/list-nid-ocr-data";
export const NID_OCR_CARDS_BY_USER = "/api/ocr/requests/list-nid-ocr-data-by-user";
export const NID_OCR_CARD_SINGLE = "/api/ocr/requests/nid-ocr-data";

export const NID_OCR_BILLING_LIST = "/api/ocr/billing/list";
export const NID_OCR_BILLING_GET = "/api/ocr/billing/get";
export const NID_OCR_BILLING_PAYMENT = "/api/ocr/billing/payment";

export const NID_SCRAPER_PING = "/api/nid-scraper/ping";
export const NID_SCRAPER_INFO = "/api/nid-scraper/nid-info";
export const NID_SCRAPER_REQUESTS = "/api/nid-scraper/requests/list";
export const NID_SCRAPER_REQUESTS_BY_USER = "/api/nid-scraper/requests/list-by-user";
export const NID_SCRAPER_VOTER_INFO = "/api/nid-scraper/requests/voter-info";
export const NID_SCRAPER_DUMP = "/api/nid-scraper/requests/dump";
export const NID_SCRAPER_REQUESTS_OVER_TIME = "/api/nid-scraper/requests/requests-over-time";
export const NID_SCRAPER_SCRAPE = "/api/nid-scraper/nid-info";

export const PORICHOY_PING = "/api/porichoy/ping";
export const PORICHOY_REQUESTS = "/api/porichoy/requests/list";
export const PORICHOY_REQUESTS_BY_USER = "/api/porichoy/requests/list-by-user";
export const PORICHOY_VOTER_INFO = "/api/porichoy/requests/voter-info";
export const PORICHOY_REQUEST_INFO = "/api/porichoy/requests/porichoy-request";
export const PORICHOY_REQUESTS_OVER_TIME = "/api/porichoy/requests/porichoy-requests-over-time";
export const PORICHOY_PAYABLE_OVER_TIME = "/api/porichoy/requests/payable-requests-over-time";
export const PORICHOY_SCRAPE = "/api/porichoy/nid-info";

export const REPORT_GET_REGISTRATION_BY_USER = "/api/report/mfs-report/get/:userId:";
export const REPORT_GENERATE = "/api/report/mfs-report/generate/";
export const REPORT_GENERATE_BATCH = "/api/report/mfs-report/generate/batch";
export const REPORT_GET_REPORT = "/api/report/mfs-report/get";

export const REPORT_CBS_GET_REGISTRATION_BY_USER = "/api/report/cbs-report/get/:userId:";
export const REPORT_CBS_GENERATE = "/api/report/cbs-report/generate/";
export const REPORT_CBS_GENERATE_BATCH = "/api/report/cbs-report/generate/batch";
export const REPORT_CBS_GET_REPORT = "/api/report/cbs-report/get";

export const CHECKER_LIST = "/api/checker-maker/list";
export const CHECKER_GET = "/api/checker-maker/get";
export const CHECKER_VERDICT = "/api/checker-maker/verdict";
